import React from "react";
import styles from './style.module.scss';
import {Input} from "../components/Form/Input";
import searchInp from '../../assets/icons/searchInp.svg';
import filtersSvg from "../../assets/icons/filters.svg";

export const BidsCart = function ({...rest}) {
  return (
    <div className={`${styles.bidsCart} ${rest.active ? styles.active: ''}`} onClick={rest?.onClick}>
      <p className={`G-fs-14`}>
        {rest.brand}, {rest.part_name}
      </p>
      <span className={`G-fs-12 ${styles.bidName}`}>
        {rest.article}
      </span>
    </div>
  )
};