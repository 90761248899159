import React, {useEffect, useState} from "react";
import styles from './style.module.scss';
import { useForm, Controller } from 'react-hook-form';
import {ButtonBase} from "../components/Form/Button";
import ХSvg from "../../assets/icons/x.svg";
import useSearchParamHelper from "../../helpers/useSearchParamHelper";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../redux/store";
import FormSelectMulti from "./formSelectMulti";
import {clearSearch, getSearch} from "../../redux/search/searchStore";
import {updateFilters} from "../../redux/filters/filtersStore";
import arrowLeft from "../../assets/icons/arrowLeft.svg";
import FormSelect from "./formSelect";

export const FilterForm = function () {
  const defaultValues = {
    name: '',
    article: '',
    exclude: {
      region_id: [],
      city_id: [],
      vendor_id: [],
      brand: [],
    },
    filters: {
      name: '',
      article: '',
      delivery:{from: '', to: ''},
      price: {from: '', to: ''}
    },
  }
  let {deleteParam, get} = useSearchParamHelper();
  const [isFormChanged, setIsFormCHanged] = useState(false);
  const [closeDropDown, setCloseDropDown] = useState(false);
  const [regionAndCityLength, setRegionAndCityLength] = useState(0);
  const [cityIds, setCityIds] = useState([]);
  const {filters: localFilters} = useSelector((state: any) => state.filters);

  const { register, getValues, reset, handleSubmit,control,setValue, formState: { errors } } = useForm();
  const dispatch = useDispatch<AppDispatch>();

  const onSubmit = data => {
    dispatch(clearSearch({}));
    dispatch(getSearch({...data, search:get('search'), brand:get('brand')}))
    dispatch(updateFilters({...data, search:get('search'), brand:get('brand')}))
    deleteParam('filterForm')
  };

  const {filters} = useSelector((state: any) => state.search);

  useEffect(() => {
    reset({...localFilters})
  }, []);

  useEffect(() => {
    // console.log(filters.regions, 3333);
    if (filters?.regions && filters?.regions.length > 0) {
      let counter = filters?.regions.length;
      let ids = [...cityIds]
      filters?.regions.forEach((item) => {
        if (item.cities.length > 0) {
          counter += item.cities.length;
          item.cities.forEach(city => ids.push(city.value));
        }
      })
      setCityIds(ids);
      setRegionAndCityLength(filters?.regions.length + ids.length);
    }
  }, [filters]);

  const handleInputChange = (e, name) => {
    const sanitizedValue = parseInt(e.target.value.replace(/[^0-9]/g, 0));
    setValue(name, sanitizedValue, { shouldValidate: true });
  };
  return (<form className={styles.formContent} onSubmit={handleSubmit(onSubmit)}>
    <div className={styles.header}>
      <img src={arrowLeft} alt="" onClick={() => {
        deleteParam('filterForm')
      }}/>
      <p className={styles.filter}>{isFormChanged ? <p onClick={() => {
        setCloseDropDown(true)
      }}>Фильтр</p>: <span onClick={() => {
        deleteParam('filterForm')
      }}>Назад</span>}</p>
      <p className={styles.clearFilters} onClick={() => {
        reset(defaultValues);
        dispatch(updateFilters(defaultValues));
        dispatch(getSearch({filters:defaultValues, search:get('search'), brand:get('brand')}))
      }}>Сбросить</p>
    </div>
    <div className={`G-p-16 ${styles.form}`}>
      <div className={styles.filtersForm}>
        {!isFormChanged && <p className='G-fs-18 bold'>Фильтр</p>}

          <div className="form-group G-mt-16">
            <label htmlFor="name">Наименование</label>
            <input
              id="filters.name"
              {...register('filters.name')}
              onChange={(e) => {}}
              className={`custom-input ${errors.name ? 'error' : ''}`}
              placeholder={'Наименование'}
            />
            {errors.name && <span className="error-message">This field is required</span>}
          </div>

          <div className="form-group">
            <label htmlFor="article">Артикул</label>
            <input
              onChange={(e) => {}}
              id="filters.article"
              {...register('filters.article')}
              defaultValue={localFilters.filters.article}
              className={`custom-input ${errors.article ? 'error' : ''}`}
              placeholder={'Артикул'}
            />
            {errors.article && <span className="error-message">This field is required</span>}
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Местоположение</label>
            <div className="custom-select-wrapper">
              <FormSelect
                closeDropDown={closeDropDown}
                handleCloseDropdown={() => setCloseDropDown(false)}
                optionClick={() => setIsFormCHanged(true)}
                getValues={getValues}
                name='region_id'
                isCities={true}
                selectedData={getValues().exclude?.region_id}
                selectedCities={getValues().exclude?.city_id}
                cityIds={cityIds}
                reset={reset}
                placeholder={`Местоположение (${regionAndCityLength - (getValues()?.exclude?.region_id?.length + getValues()?.exclude?.city_id?.length) || '0'})`}
                options={filters ? filters.regions : []}
                openTop={false}/>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="price">Цена</label>
            <div className={styles.fromTo}>
              <input
                id="filters.price.from"
                {...register('filters.price.from', {valueAsNumber: true })}
                className={`custom-input`}
                defaultValue={localFilters?.filters?.price?.from}

                placeholder={'От'}
                type='number'
                onChange={(e) => {
                  handleInputChange(e,'filters.price.from' )
                }}
              />
              <input
                id="filters.price.to"
                {...register('filters.price.to', {valueAsNumber: true })}
                className={`custom-input`}
                placeholder={'До'}
                defaultValue={localFilters.filters.price.to}
                type='number'
                onChange={(e) => {
                  handleInputChange(e,'filters.price.to' )
                }}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="delivery">Срок поставки</label>
            <div className={styles.fromTo}>
              <input
                id="filters.delivery"
                {...register('filters.delivery.from', {valueAsNumber: true })}
                className={`custom-input`}
                type='number'
                defaultValue={localFilters.filters.delivery.from}
                onChange={(e) => {
                  handleInputChange(e,'filters.delivery.from' )
                }}
                placeholder={'От'}
              />
              <input
                id="delivery"
                {...register('filters.delivery.to', {valueAsNumber: true })}
                className={`custom-input`}
                defaultValue={localFilters.filters.delivery.to}

                type='number'
                onChange={(e) => {
                  handleInputChange(e,'filters.delivery.to' )
                }}
                placeholder={'До'}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Поставщик</label>
            <div className="custom-select-wrapper">
              <FormSelect
                closeDropDown={closeDropDown}
                handleCloseDropdown={() => setCloseDropDown(false)}
                optionClick={() => setIsFormCHanged(true)}
                getValues={getValues}
                name='vendor_id'
                selectedData={getValues().exclude?.vendor_id}
                reset={reset}
                placeholder={`Поставщик (${filters?.vendors.length - getValues().exclude?.vendor_id?.length || '0'})`}
                options={filters ?filters.vendors : []}
                openTop={true}/>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Бренд</label>
            <div className="custom-select-wrapper">
              <FormSelect
                closeDropDown={closeDropDown}
                handleCloseDropdown={() => setCloseDropDown(false)}
                optionClick={() => setIsFormCHanged(true)}
                getValues={getValues}
                name='brand'
                selectedData={getValues().exclude?.brand}
                reset={reset}
                placeholder={`Бренд (${filters?.brands.length - getValues().exclude?.brand?.length || '0'})`}
                options={filters ? filters.brands : []}
                openTop={true}/>
            </div>
          </div>
      </div>

    </div>
    <div className={styles.sbmBtn}>
        <ButtonBase >
          Применить фильтр
        </ButtonBase>
      </div>
  </form>
  )
};