import React, {useEffect, useState} from "react";
import MeanSvg from '../../assets/icons/mean.svg';
import styles from './style.module.scss';
import burgerSvg from '../../assets/icons/menu.svg';
import logoSvg from '../../assets/icons/logo.svg';
import searchSvg from '../../assets/icons/search.svg';
import loginSvg from '../../assets/icons/logIn.svg';
import addSvg from '../../assets/icons/add.svg';
import filtersSvg from '../../assets/icons/filters.svg';
import arrowLeft from '../../assets/icons/arrowLeft.svg';
import {OptionBtn} from "../components/optionBtn";
import {Empty} from "../components/empty";
import {Search} from "./search";
import {Filters} from "./filters";
import {Options} from "./options";
import {FilterForm} from "../rightSection/filterForm";
import { useBreakpointsContext } from "../../helpers/breakpoints";
import {useSearchParams} from "react-router-dom";
import {getAds} from "src/redux/ads/adsStore";
import {getSearch, clearSearch} from "src/redux/search/searchStore";
import {getPreSearch} from "src/redux/preSearch/preSearchStore";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../redux/store";
import {BidsCart} from "./bidsCart";
import useSearchParamHelper from "../../helpers/useSearchParamHelper";
import {Product} from "../rightSection/product";
import {Results} from "../results";
import LineLoader from "../results/components/lineLoader";

let options = [
  {name:'Мои заявки'},
  {name:'Не опубликованные'},
  {name:'Опубликовано'},
  {name:'Заказано'},
  {name:'Исполнено'},
  {name:'Возврат'},
  {name:'Возвращено'},
  {name:'Архив'},
];

let searchOptions = [
  {name:'Объявления'},
  {name:'Заявки'},
]

export const Nav = function () {
  const { isMobile } = useBreakpointsContext();
  const {get, deleteParam, add}=useSearchParamHelper()
  const [showSearch, setShowSearch] = useState(false);
  const [activeOption, setActiveOption] = useState('Мои заявки');
  const [activeBid, setActiveBid] = useState(null);
  const [activeOptions, setActiveOptions] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  const {data:search, isLoading} = useSelector((state: any) => state.search);
  const dispatch = useDispatch<AppDispatch>();
  const preSearch = useSelector((state: any) => state.preSearch);
  const {filters} = useSelector((state: any) => state.filters);

  const handleFilter = () => {
    setSearchParams({filterForm: '1'});
    if (searchParams.get('filterForm')) {
      setSearchParams((params) => {
        params.delete('filterForm');
        return params;
      });
    }
  }

  useEffect(() => {
    if (showSearch && get('search') && preSearch && preSearch.data?.length > 0) {
      setActiveOptions([])
      setActiveOption('Объявления');
    } else if (!showSearch) {
      setActiveOptions(options);
    } else setActiveOptions([]);

    return () => {
      setActiveBid(null)
    }
  }, [showSearch, preSearch]);


  useEffect(() => {
    if (get('search')) {
      dispatch(getPreSearch({search:get('search')}))
      setShowSearch(true)

      if (get('brand')) {
        // dispatch(getSearch({brand:get('brand'), search:get('search')}))
      }
    }
  }, []);

  useEffect(() => {
    if (preSearch?.data && preSearch?.data.length > 0) {
      add('brand', preSearch?.data[0].brand)
      // dispatch(getSearch({brand:preSearch?.data[0].brand, search:get('search')}))
    } else dispatch(clearSearch({}))
  }, [preSearch]);


  const handleSearch = (text) => {
    dispatch(clearSearch({}))
    dispatch(getPreSearch({search:text.value}))
    setSearchParams({search: text.value});

    if (!text.value) {
      setSearchParams((params) => {
        params.delete('search');
        return params;
      });
    }
  }

  if (isMobile) {
    if (get('filterForm')) return <div className={styles.nav}><FilterForm /></div>;
    if (get('activeOffer')) return <div className={styles.nav}><Product /></div>;
    if (activeBid !== null) return  <div className={styles.nav}><Results handleClose={() => setActiveBid(null)} forMobile={true}/></div>
  }

  const preSearchTitle = preSearch?.data?.length > 0 ?
    `Найдено ${preSearch?.data?.length} ${preSearch?.data?.length == 1 ? 'уточнение'
      : (preSearch?.data?.length > 1 && preSearch?.data?.length < 5) ? 'уточнения'
        : preSearch?.data?.length > 5 ? 'уточнений': 'уточнений'}`
    :'Не найдено уточнений';

  const searchHistoryTitle = 'История поиска';

  const myBidTitle = 'Мои заявки';
  const searchTitle = 'Ищем уточнения';

  return (
    <div className={styles.nav}>
      <div className={styles.navContent} style={{display: `${isMobile && (get('filterForm') || get('activeOffer') || activeBid) ? 'none' : ''}`}}>
        <div className={`${styles.leftHeader} ${activeOptions.length > 0 ? styles.paddingVertical16 : ''} ${showSearch ? styles.isSearch : ''}`}>
          <div className={styles.header}>
            {(!showSearch && !get('search')) && (
              <div className={styles.actions}>
                <div className={styles.rightSection}>
                  <div className={styles.burgerMenu}>
                    {/* Burger Menu Icon */}
                    <img src={burgerSvg} alt="xc"/>
                  </div>
                  <div className={styles.logo} onClick={() => {
                    setShowSearch(false)
                    // handleSearch('');
                    dispatch(clearSearch({}))
                    deleteParam('activeOffer')
                    deleteParam('brand')
                    deleteParam('filterForm')
                  }}>
                    {/* Logo */}
                    <img src={logoSvg} alt="Logo" onClick={() => window.location.reload()}/>
                  </div>
                </div>
                <div className={styles.icons}>
                  {/* Icons */}
                  <img src={loginSvg} alt="xc" />
                  <img src={searchSvg} alt="xc" onClick={() => setShowSearch(true)}/>
                  <img src={addSvg} alt="xc"/>
                </div>
              </div>
            )}
            {(showSearch || get('search'))  && (
              <div className={styles.search}>
                <img src={arrowLeft} alt="" onClick={() => {
                  setShowSearch(false)
                  dispatch(clearSearch({}))
                  deleteParam('activeOffer')
                  deleteParam('brand')
                  deleteParam('search')
                  deleteParam('filterForm')
                }}/>
                <Search text={get('search')}  handleSearch={handleSearch}/>
              </div>
            )}
          </div>
          {(activeOptions.length > 0)  && (
            <div style={{padding:`${showSearch && preSearch.data?.length > 0 ? '15px 0 0 0': ''}`}}>
              <Options>
                {activeOptions.map((item, key) => {
                  return <OptionBtn onClick={() => setActiveOption(item.name)} key={key} text={item.name} isActive={item.name === activeOption}/>
                })}
              </Options>
            </div>
          )}
        </div>
        <Filters text={preSearch.isLoading ? searchTitle:showSearch && !get('search') ? searchHistoryTitle : !showSearch && !get('search') ? myBidTitle: preSearchTitle} handleFilter={() => {}} showFilter={false}/>
        {preSearch.isLoading ? <></> : (
          <div className={`special-content custom-scroll ${styles.list}`}>
            {showSearch && !get('search') ? (
              <Empty type={'search'}/>
            ) : !showSearch && !get('search') ? <Empty type={'bid'}/> : (
              <>
                {preSearch?.data && preSearch?.data.length > 0 ?
                  preSearch?.data.map((result, key) => {
                    return <BidsCart {...result} key={key} active={(key === activeBid)} onClick={() => {
                      setActiveBid(key);
                      deleteParam('activeOffer')
                      add('brand', result.brand)
                      dispatch(clearSearch({}))
                      dispatch(getSearch({...filters, brand:result.brand, search:get('search')}))
                    }
                    }/>;
                  })
                  : <Empty type={'emptySearch'}/>}
              </>
            )}

          </div>
        )}
      </div>
    </div>
  )
};