import React, {useEffect, useState} from "react";
import MeanSvg from '../../assets/icons/mean.png';
import styles from './style.module.scss';
import {useSearchParams} from "react-router-dom";
import { useForm } from 'react-hook-form';
import {ButtonBase} from "../components/Form/Button";
import ХSvg from "../../assets/icons/x.svg";
import Img from "../../assets/img.png";
import useSearchParamHelper from "../../helpers/useSearchParamHelper";
import {useSelector} from "react-redux";
import Slider from "./Slider";
import arrowLeft from "../../assets/icons/arrowLeft.svg";
import {convertDateString, daysLeft, formatRussianPhoneNumber} from "../../helpers/utiles";
import { useBreakpointsContext } from "../../helpers/breakpoints";


export const Product = function () {
  let {deleteParam, get} = useSearchParamHelper();
  const [offer, setOffer] = useState(null);
  const [showTel, setShowTel] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);
  const offers = useSelector((state: any) => state.search);
  const { isMobile } = useBreakpointsContext();
  useEffect(() => {
    const findOffer = offers.data.find(item => item.itemId == get('activeOffer'));
    console.log(findOffer, 'findOffer');
    setOffer(findOffer)
    setShowTel(false)
  }, [get('activeOffer')]);

  useEffect(() => {
    return () => {
      setShowTel(false)
    };
  }, []);


  return (<>
    <div className={styles.header}>
      <div className={styles.headerActions}>
        <img src={arrowLeft} alt="" onClick={() => {
          deleteParam('activeOffer')
        }}/>
        <p onClick={() => {
          deleteParam('activeOffer')
        }}>Назад</p>
      </div>
    </div>
      {offer && (
        <>
          {offer.photo && (
            <div className={styles.slider}>
              <Slider images={[offer.photo]}/>
            </div>
          )}
          <div className={`${styles.productInfo} G-p-16`}>
            <p className={`G-fs-21 ${styles.price}`}>
              {isMobile ? offer.price.formatted.replace('р.', '₽') : offer.price.formatted.replace('р.', '')}
            </p>
            <p className={`G-fs-18 ${styles.title}`}>{offer.title}</p>

            <div className={styles.details}>
              <div className={styles.item}>
                <span className='G-text-gray'>Бренд</span>
                <span className=''>{offer.brand}</span>
              </div>
              <div className={styles.item}>
                <span className='G-text-gray'>Артикул</span>
                <span className=''>{offer.article}</span>
              </div>
              <div className={styles.item}>
                <span className='G-text-gray'>Количество</span>
                <span className=''>{offer.quantity.formatted}</span>
              </div>
              <div className={styles.item}>
                <span className='G-text-gray'>Поставщик</span>
                <span className=''>{offer.vendor_title}</span>
              </div>
              <div className={styles.item}>
                <span className='G-text-gray'>Местоположение</span>
                <span className=''>{offer.warehouse}</span>
              </div>
              <div className={styles.item}>
                <span className='G-text-gray'>Срок поставки</span>
                <span className=''>{daysLeft(offer.delivery)}</span>
              </div>
              <div className={styles.item}>
                <span className='G-text-gray'>Дата публикации</span>
                <span className=''>{convertDateString(offer?.created_at)}</span>
              </div>
            </div>
          </div>
          <div className={styles.action}>
            <ButtonBase type={showTel ? 'link': 'primary'} onClick={() => {setShowTel(!showTel)}}>
              {!showTel ? 'Показать телефон' : offer.vendor_phone ? formatRussianPhoneNumber(offer.vendor_phone) : 'номер телефона отсутствует'}
            </ButtonBase>
          </div>
        </>
      )}
  </>
  )
};