import React, {useEffect, useState} from "react";
import MeanSvg from '../../assets/icons/mean.png';
import {ButtonBase} from "../components/Form/Button";
import Paths from "../../routes/paths";
import styles from './style.module.scss';
import {Empty} from "../components/empty";
import {Options} from "../nav/options";
import {OptionBtn} from "../components/optionBtn";
import {Filters} from "../nav/filters";
import { useSearchParams } from "react-router-dom";
import {OfferCart} from "./offerCart";
import {useDispatch, useSelector} from "react-redux";
import useSearchParamHelper from "../../helpers/useSearchParamHelper";
import LineLoader from "./components/lineLoader";
import {getSearch} from "../../redux/search/searchStore";
import {AppDispatch} from "../../redux/store";
import InfiniteScroll from "react-infinite-scroll-component";
import { useBreakpointsContext } from "../../helpers/breakpoints";


export const Results = function ({forMobile = false, handleClose = () => {}}) {
  const {add, get, deleteParam, replace} = useSearchParamHelper()
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeOffer, setActiveOffer] = useState(null);
  const {data, isLoading, items_count} = useSelector((state: any) => state.search);
  const {filters} = useSelector((state: any) => state.filters);
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { isMobile } = useBreakpointsContext();


  let options = [
    {name:'Объявления'},
    {name:'Заявки'},
  ]

  const handleFilter = () => {
    replace('activeOffer', 'filterForm', '1');
    if (get('filterForm')) {
      deleteParam('filterForm');
    }
  }

  const fetchMoreData = (data) => {
    dispatch(getSearch({...filters, page:page, search:get('search'), brand:get('brand')}))
    setPage(prevPage => prevPage + 1);
    if (data.length >= items_count) {
      setHasMore(false)
    }
  }

  return (
    <div className={styles.results}>
      {!isMobile && (
      <div className={styles.header}>

          <Options>
            {options.map((item, key) => {
              return <OptionBtn onClick={() => setActiveIndex(key)} key={key} text={item.name} isActive={key === activeIndex}/>
            })}
          </Options>

      </div> )}
      <Filters showFilter={!isLoading} handleClose={handleClose} icon={forMobile} handleFilter={handleFilter} text={isLoading ? 'Ищем объявление...': data?.length > 0
        ?`Найдено ${items_count} ${items_count == 1 ? 'объявление'
          : (items_count > 1 && items_count < 5) ? 'объявления'
            : items_count > 5 ? 'объявлений': 'объявлений'}` : 'Объявления'}/>
      {isLoading && <LineLoader isLoading={isLoading}/>}
        <div id="scrollableDiv" className={`special-content custom-scroll ${styles.offers}`}>
            <InfiniteScroll
              dataLength={data.length}
              next={() => fetchMoreData(data)}
              scrollableTarget="scrollableDiv"
              hasMore={hasMore}
              loader={<span></span>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                </p>
              }>

            {data?.map((item, key) => {
              return <OfferCart {...item} key={key} onClick={() =>{
                add('activeOffer', `${item.itemId}`)
                replace('filterForm', 'activeOffer', `${item.itemId}`);
              } } active={item.itemId == get('activeOffer')}/>
            })}
            </InfiniteScroll>
          {data.length === 0 && (
            <div></div>
          )}
        </div>
    </div>
  )
};