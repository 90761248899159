import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

export interface searchState {
  data: any
  isLoading: boolean,
  filters:null,
  items_count:0,
  error?: any
}

const initialState: searchState = {
  data: [],
  filters:null,
  items_count:0,
  isLoading: false
}

export const getSearch = createAsyncThunk('search/getSearch', async (args: any, thunkAPI) => {
  try {
    console.log(args, 44);
    let resData = {...args}
    return axios.post('https://test.qrq.ru/qwep/api/v1/search', {
      "clear_cache": false,
      "page_size": 30,
      "page": args.page || 1,
      ...resData
    })
  } catch (err: any) {
    console.log(err.message);
    return thunkAPI.rejectWithValue({ error: err.message })
  }
})

export const SearchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clearSearch: (state, action) => {
      state.isLoading = false
      state.data = [];
      state.filters = null;
    }},
  extraReducers:(builder) => {
    builder.addCase(getSearch.pending, (state:searchState) => {
      state.isLoading = true
    })
    builder.addCase(getSearch.fulfilled, (state:typeof initialState, action: any) => {
      let options:any = {};

      Object.keys(action.payload?.data?.filters).forEach((key) => {
        if (key === 'vendors' || key === 'regions') {
          options[key] = action.payload?.data?.filters?.[key].map((item) => {

            return {value:item.id, label:item.title ? item.title.split(';')[0] : item.name ? item.name.split(';')[0] : '', cities: item?.cities ? item?.cities?.map(city => {return {value:city.id, label: city.title}}) : []}
          });
        } else {
          options[key] = action.payload?.data?.filters?.[key].map((value) => {
            return {value:value, label: value}
          })
        }
      })

      state.data = [...state.data, ...action.payload?.data?.items];
      state.filters = options;
      state.items_count = action.payload?.data?.items_count;
      state.isLoading = false;
    })
    builder.addCase(getSearch.rejected, (state:searchState, action) => {
      state.isLoading = false
      state.data = [];
      state.filters = null;
      // state.error = action.error.message
    })
  }
});

export const { clearSearch } = SearchSlice.actions


export default SearchSlice.reducer;