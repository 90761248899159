import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

export interface preSearchState {
  data: any
  isLoading: boolean,
  error?: any
}

const initialState: preSearchState = {
  data: [],
  isLoading: false
}

export const getPreSearch = createAsyncThunk('preSearch/getPreSearch', async ({...args}: any, thunkAPI) => {
  try {
    return axios.post('https://test.qrq.ru/qwep/api/v1/pre_search', {...args})
  } catch (err: any) {
    console.log(err.message);
    return thunkAPI.rejectWithValue({ error: err.message })
  }
})

export const PreSearchSlice = createSlice({
  name: 'preSearch',
  initialState,
  reducers: {},
  extraReducers:(builder) => {
    builder.addCase(getPreSearch.pending, (state:preSearchState) => {
      state.isLoading = true
    })
    builder.addCase(getPreSearch.fulfilled, (state:typeof initialState, action: any) => {
      state.data = action.payload?.data;
      state.isLoading = false;
    })
    builder.addCase(getPreSearch.rejected, (state:preSearchState, action) => {
      state.isLoading = false
      // state.error = action.error.message
    })
  }
});

export default PreSearchSlice.reducer;