import React, {useRef} from "react";
import './style.scss';
import {ButtonBase} from "../Button";
import {Link} from "react-router-dom";
import Paths from "../../../../routes/paths";

interface IInput {
  disable?: boolean,
  classes?: string,
  label?: string,
  icon?: string,
  isSearch?: boolean,
  placeHolder?: string
  placeholder?: string
  defaultValue?: string
  handleSearch?: (text:any) => void
}
export function Input({classes, disable, label, icon, placeholder, isSearch, handleSearch, ...rest}: IInput) {
  const ref = useRef();
  return (<div className='baseInput'>
      {label && <label>{label}</label>}
      <div className='inputSection'>
        {icon &&
	        <img className='inputIcon' src={icon} alt="" onClick={() => handleSearch(ref.current)}/>
        }
        <input onKeyDown={(e) => e.key === 'Enter' && handleSearch(ref.current)} ref={ref} {...rest} placeholder={placeholder} className={` ${classes ? classes : ''}`} disabled={disable} />
      </div>
    </div>
  );
}