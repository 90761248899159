import React from "react";
import {Route, Outlet} from "react-router-dom";
import Header from "./header";
import './style.scss';
import Footer from "./footer";

const Main = ({children}: any) => {
  return (
    <div className='G-container'>
      <div className="G-page">
        {/*<Header/>*/}
        <Outlet/>
        {/*<Footer/>*/}
      </div>
    </div>
  )
};

export default Main;
