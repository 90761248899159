import React, {Suspense, useState} from "react";
import {BrowserRouter, Navigate, Routes, Route} from "react-router-dom";
import Main from "../modules/layouts/main";
import {
  Home,
} from "../modules/home";
import {Auth} from "../modules/auth";
import Paths from './paths';
import '../App.scss';

const AppRoutes = () => {
  return (
    // <Suspense fallback={<h2>Loading...</h2>}>
            <Routes>
              <Route element={<Main />}>
                <Route index element={<Home />} />
                <Route  path={Paths.HOME} element={<Home/>}/>
                <Route  path={Paths.AUTH} element={<Auth/>}/>
              </Route>
              {/*<Route path="*" element={<Navigate to={Paths.HOME} replace />} />*/}
            </Routes>
    // </Suspense>
  )
};

export default AppRoutes;
