import { useState } from 'react';
import {useSearchParams} from "react-router-dom";

function useSearchParamHelper(initialValue = 0) {
  let [searchParams, setSearchParams] = useSearchParams();

  const add = (key,value) => {
    const paramsObject = Object.fromEntries(searchParams.entries());
    setSearchParams({...paramsObject, [key]:`${value}`})
  };
  const deleteParam = (key) => {
    setSearchParams((params) => {
      params.delete(key);
      return params;
    });
  };
  const replace = (from, to, value) => {
    deleteParam(`${from}`);
    add(`${to}`, value)

  }
  const get = (key) => searchParams.get(key)

  return { add, deleteParam, get, replace };
}

export default useSearchParamHelper;