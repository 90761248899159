import { createContext, useContext, useEffect, useState } from "react";

export const BreakpointsContext = createContext();

export default function BreakpointsContextProvider({ children }) {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isLaptopsmall, setIsLaptopsmall] = useState(false);
  const [isLaptop, setIsLaptop] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const breakpointsArray = {
    mobile: setIsMobile,
    tablet: setIsTablet,
    laptopsmall: setIsLaptopsmall,
    laptop: setIsLaptop,
    desktop: setIsDesktop,
  };

  const breakpoints = [
    { mobile: 0 },
    { tablet: 576 },
    { laptopsmall: 1024 },
    { laptop: 1400 },
    { desktop: 1600 },
  ];

  useEffect(() => {
    function defineBreakpoins(key) {
      for (const point in breakpointsArray) {
        breakpointsArray[point](key === point ? true : false);
      }
    }

    function resizeHandler() {
      const windowSize = window.innerWidth;

      breakpoints.forEach((point, i) => {
        const prevBreakpoint = breakpoints[i - 1]
          ? Object.entries(breakpoints[i - 1]).flat()[1]
          : false;
        const currBreakpoint = breakpoints[i]
          ? Object.entries(breakpoints[i]).flat()[1]
          : false;
        const nextBreakpoint = breakpoints[i + 1]
          ? Object.entries(breakpoints[i + 1]).flat()[1]
          : false;

        for (const key of Object.keys(point)) {
          if (
            +currBreakpoint <= windowSize &&
            +prevBreakpoint < windowSize &&
            +nextBreakpoint >= windowSize
          ) {
            defineBreakpoins(key);
          }

          if (
            nextBreakpoint === false &&
            +currBreakpoint <= windowSize &&
            +prevBreakpoint < windowSize
          ) {
            defineBreakpoins(key);
          }
        }
      });
    }

    resizeHandler();
    window.addEventListener("resize", resizeHandler);
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  const context = {
    isMobile,
    isTablet,
    isLaptopsmall,
    isLaptop,
    isDesktop,
  };

  return (
    <BreakpointsContext.Provider value={context}>
      {children}
    </BreakpointsContext.Provider>
  );
}

export const useBreakpointsContext = () => {
  return useContext(BreakpointsContext);
};
