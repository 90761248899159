import React from "react";
import './style.scss';
import {Nav} from "../nav";
import {Results} from "../results";
import {RightSection} from "../rightSection";
import { useBreakpointsContext } from "../../helpers/breakpoints";

export const Home = function () {
  const { isMobile } = useBreakpointsContext();
  return (
    <div className={`home`}>
      <Nav/>
      {!isMobile &&
       <>
	       <Results/>
	       <RightSection/>
       </>
      }
    </div>
  )
};