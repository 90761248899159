import React, {useEffect, useState} from 'react';
import styles from './CustomSelect.module.scss';
import searchInp from "../../assets/icons/searcGray.svg";
import selectSvg from "../../assets/icons/select.svg";

const FormSelect = ({isCities = false, handleCloseDropdown,cityIds = [], selectedCities = [], closeDropDown = false, getValues, selectedData, name, reset, placeholder = '', options = [], openTop = false, optionClick = () => {} }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTerm, setSearchTerm] = useState('');

  const toggleDropdown = (e) => {
    e.stopPropagation()
    setIsOpen(!isOpen);
    handleCloseDropdown()
  };

  useEffect(() => {
    setFilteredOptions(options)
  }, [options]);

  useEffect(() => {
    if (closeDropDown) {
      setIsOpen(false)
    }
  }, [closeDropDown]);

  const filterOptions = (term) => {
    const lowercasedTerm = term.toLowerCase();

    const filtered = options.map(region => {
      const matchingCities = region.cities ? region.cities.filter(city =>
        city.label.toLowerCase().includes(lowercasedTerm)
      ): [];

      if (region.label.toLowerCase().includes(lowercasedTerm) || matchingCities.length > 0) {
        return {
          ...region,
          cities: matchingCities,
        };
      }
      return null;
    }).filter(region => region !== null);

    setFilteredOptions(filtered);
  };


  const handleOptionClick = (option, isCity = false) => {
    setSelectedOption(option);

    let options = [];
    optionClick();
    let exclude:any = {...getValues().exclude}

    if (isCity) {
      if (selectedCities.includes(option.value)) {
        options = selectedCities.filter(item => item !== option.value && item !== null)
      } else {
        options = [...selectedCities, option.value];
      }
      reset({ exclude:{...getValues().exclude, city_id:options}});
    } else {

      if (selectedData.includes(option.value)) {
        options = selectedData.filter(item => item !== option.value && item !== null)
        exclude[name] = options;
        exclude.city_id = exclude.city_id.filter((cityId) => {
          let getIds = option.cities.map(item => item.value)
          return !getIds.includes(cityId)
        });
      } else {
        options = [...selectedData, option.value];
        exclude[name] = options;
        exclude.city_id = [...exclude.city_id, ...option?.cities.map(item => item.value)]
      }
      reset({ exclude:exclude});
    }
    // setSearchTerm('');
  };

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    filterOptions(term);
  };


  const currentOptions = getValues().exclude?.[name] || [];
  const citiesOptions = getValues().exclude?.city_id || [];

  const handleClearAll = () => {
    let exclude = {...getValues().exclude}

    if (isCities) {
      exclude.city_id = [];
      exclude.region_id = []
    } else exclude[name] = [];
    reset({ exclude:exclude});
  };

  const handleSelectAll = () => {
    if (isCities) {
      reset({ exclude:{...getValues().exclude, region_id:options.map(item => item.value), city_id: cityIds}});
    } else {
      reset({ exclude:{...getValues().exclude, [name]:options.map(item => item.value)}});
    }
  };

  return (
    <div className={styles.selectContainer}>
      {isCities ? (
        <p onClick={citiesOptions.length > 0 ? () => handleClearAll() : () => handleSelectAll()} className={styles.selectOrClear} >
          {`${citiesOptions.length > 0 ? 'Выбрать все ' : 'Отменить все'}`}
        </p>
      ): <p onClick={currentOptions.length > 0 ? handleClearAll : handleSelectAll} className={styles.selectOrClear} >
        {`${currentOptions.length > 0 ? 'Выбрать все' : 'Отменить все'}`}
      </p>}

      <div className={`${styles.selectBox} ${(isOpen && openTop && filteredOptions.length > 0) ? styles.selectBoxOpenTop : (isOpen && !openTop && filteredOptions.length > 0) ? styles.selectBoxOpenBottom : ''}`} onClick={toggleDropdown}>
        {isOpen ? (
          <div style={{ position: 'absolute', left: '8px', top: '51%', transform: 'translateY(-50%)', zIndex: '20'}}>
            <img src={searchInp} alt="" style={{color:"gray"}}/>
          </div>
        ) : <span>{placeholder}</span>}
        {isOpen && (
          <input
            type="text"
            className={`${styles.searchInput} ${isOpen ? styles.isOPen : ''}`}
            placeholder="Поиск"
            value={searchTerm}
            onChange={handleSearchChange}
            onClick={toggleDropdown}
            autoFocus
          />
        )}
        <img className={`${styles.arrow} ${isOpen ? styles.open : ''}`} src={selectSvg} alt=""/>

      </div>
      {isOpen && filteredOptions.length > 0 && (
        <div className={`special-content custom-scroll ${styles.dropdown} ${openTop ? styles.dropdownListTop : styles.dropdownList}`}>
          {filteredOptions.map((option, key) => (
            <div>
              {option.value && <div
                key={key}
                className={`${styles.dropdownOption} ${!selectedData?.includes(option.value) ? styles.selected : ''}`}
                onClick={(e) => {
                  e.stopPropagation()
                  handleOptionClick(option)
                }}
              >
                {option.label}
              </div>}

              {option?.cities?.length > 0 && option?.cities.map((city, key) => {
                return <div
                  key={key}
                  style={{marginLeft:`${!option.value ? 'unset' : '20px'}`}}
                  className={`${styles.dropdownOption} ${styles.optionCity}  ${!selectedCities?.includes(city.value) ? styles.selected : ''}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    handleOptionClick(city, true)
                  }}
                >
                  <p>{city.label}</p> <span>- {option.label}</span>
                </div>
              })}

            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FormSelect;
