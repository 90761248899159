import React from "react";
import styles from './style.module.scss';
import {Input} from "../components/Form/Input";
import searchInp from '../../assets/icons/searchInp.svg';
import filtersSvg from "../../assets/icons/filters.svg";
import arrowLeft from "../../assets/icons/arrowLeft.svg";

export const Filters = function ({text = '', handleFilter = () => {}, handleClose = () => {}, showFilter = true, icon = false }) {
  return (
    <div className={styles.filters}>
      <p className='G-fs-18' onClick={handleClose}>
        {icon && (
          <img src={arrowLeft} alt="" />
        )}
        {text}
      </p>
      {showFilter && <img src={filtersSvg} alt="" onClick={handleFilter}/>}
    </div>
  )
};