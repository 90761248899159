import React, { useState, useEffect } from 'react';
import './Loader.css';

const LineLoader = ({ isLoading }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!isLoading) {
      setProgress(100);
      return;
    }

    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 85) {
          clearInterval(interval);
          return prev;
        }
        return prev + Math.random() * 10;
      });
    }, Math.random() * 1000);

    return () => clearInterval(interval);
  }, [isLoading]);

  return (
    <div className="loader-container">
      <div className="loader" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default LineLoader;
