import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export interface filtersState {
  filters: any
}

const initialState: filtersState = {
  filters: {
    name: '',
    article: '',
    exclude: {
      city_id: [],
      region_id: [],
      vendor_id: [],
      brand: [],
    },
    filters: {
      delivery:{from: '', to: ''},
      price: {from: '', to: ''}
    },
  }
}

export const FiltersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateFilters: (state, action) => {
      if (action.payload === null) {
        state.filters = {}
      } else {
        let getFilters = {...state.filters, ...action.payload}
        if (getFilters?.filters?.price) {
          getFilters.filters.price.to = parseInt(getFilters.filters.price.to || null)
          getFilters.filters.price.from = parseInt(getFilters.filters.price.from || null)
          getFilters.filters.delivery.from = parseInt(getFilters.filters.delivery.from || null)
          getFilters.filters.delivery.to = parseInt(getFilters.filters.delivery.to || null)
        }
        state.filters = getFilters
      }
    },
  },
})

export const { updateFilters } = FiltersSlice.actions

export default FiltersSlice.reducer