function isValidDate(dateStr) {
  const dateParts = dateStr.split('.');
  if (dateParts.length !== 3) return false;

  const [day, month, year] = dateParts.map(part => parseInt(part, 10));

  if (isNaN(day) || isNaN(month) || isNaN(year)) return false;
  if (year < 1000 || year > 9999 || month < 1 || month > 12 || day < 1 || day > 31) return false;

  const date = new Date(year, month - 1, day);
  return date.getDate() === day && date.getMonth() === month - 1 && date.getFullYear() === year;
}

export function convertDateString(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes}, ${day}.${month}.${year}`;
}

export function daysLeft(targetDateStr) {

  if (targetDateStr) {
    if (!isValidDate(targetDateStr)) {
      return targetDateStr;
    }
    if (!targetDateStr.includes('2024')) {
      targetDateStr = targetDateStr + '.2024'
    }

    const targetDate = new Date(targetDateStr.split('.').reverse().join('-'));
    const currentDate = new Date();

    if (currentDate > targetDate) {
      return null;
    }

    let years = targetDate.getFullYear() - currentDate.getFullYear();
    let months = targetDate.getMonth() - currentDate.getMonth();
    let days = targetDate.getDate() - currentDate.getDate();

    if (days < 0) {
      months--;
      days += new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    let result = [];
    if (years > 0) {
      result.push(`${years} ${years === 1 ? 'год' : (years < 5 ? 'года' : 'лет')}`);
    }
    if (months > 0) {
      result.push(`${months} ${months === 1 ? 'месяц' : (months < 5 ? 'месяца' : 'месяцев')}`);
    }
    if (days > 0) {
      result.push(`${days} ${days === 1 ? 'день' : (days < 5 ? 'дня' : 'дней')}`);
    }

    return result.join(', ');
  }

}

export function isEqual(obj1, obj2) {
  // Check if both values are the same instance or primitive value
  if (obj1 === obj2) return true;

  // If either is not an object or is null, they are not equal
  if (obj1 == null || obj2 == null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }

  // Get the keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // If the number of keys is different, the objects are not equal
  if (keys1.length !== keys2.length) return false;

  // Check if all keys and values are equal
  for (let key of keys1) {
    if (!keys2.includes(key) || !isEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export function formatRussianPhoneNumber(phoneNumber) {
  // Remove all non-digit characters except the leading '+'
  let cleaned = ('' + phoneNumber).replace(/[^\d+]/g, '');

  // Ensure the number starts with +7 and has the correct length
  if (!cleaned.startsWith('+7') || cleaned.length !== 12) {
    return 'Invalid phone number';
  }

  // Remove the leading '+'
  cleaned = cleaned.slice(1);

  // Format the number
  let formatted = '+7 (' + cleaned.slice(1, 4) + ') ' + cleaned.slice(4, 7) + '-' + cleaned.slice(7, 9) + '-' + cleaned.slice(9);
  return formatted;
}