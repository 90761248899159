import React from "react";
import styles from './style.module.scss';
import {Input} from "../components/Form/Input";
import searchInp from '../../assets/icons/searchInp.svg';
import filtersSvg from "../../assets/icons/filters.svg";

export const Options = function ({children }) {
  return (
    <div className={styles.options}>
      {children}
    </div>
  )
};