import adsStore from "./ads/adsStore";
import bidsStore from "./bids/bidsStore";
import offersStore from "./offers/offersStore";
import searchStore from "./search/searchStore";
import preSearchStore from "./preSearch/preSearchStore";
import filtersStore from "./filters/filtersStore";

export const reducers = {
  ads: adsStore,
  bids: bidsStore,
  offers: offersStore,
  search: searchStore,
  preSearch: preSearchStore,
  filters: filtersStore,
}