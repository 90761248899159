import React from "react";
import styles from './style.module.scss';
import empty from '../../../assets/icons/empty.svg';
import searchEmpty from '../../../assets/icons/searchEmpty.svg';

export const Empty = function ({type = 'bid'}) {

  const contentByType = {
    search: <div className={`${styles.empty}`}>
      <div className={styles.icon}>
        <img src={searchEmpty}/>
      </div>
      <div className={`${styles.title} G-fs-16`}>
        Ваша история поиска пустая
      </div>
      <div className={`${styles.text} G-fs-12 G-text-light-gray`}>
        Тут будут храниться ваши <br/> последние запросы поиска
      </div>
    </div>,
    bid:<div className={`${styles.empty}`}>
      <div className={styles.icon}>
        <img src={empty}/>
      </div>
      <div className={`${styles.title} G-fs-16`}>
        Ваш список заявок пуст
      </div>
      <div className={`${styles.text} G-fs-12 G-text-light-gray`}>
        Что бы разместить свою первую заявку <br/> используйте «+» в верхней части экрана
      </div>
    </div>,
    emptySearch:<div className={`${styles.empty}`}>
      <div className={styles.icon}>
        <img src={searchEmpty}/>
      </div>
      <div className={`${styles.title} G-fs-16`}>
        По данному запросу <br/>
        ничего не найдено
      </div>
    </div>
  }

  return contentByType[type]
};