import React, { useState } from 'react';
import './slider.css';

function Slider({images = []}) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setStartX(touch.clientX);
  };

  const handleTouchMove = (e) => {
    if (!startX) return;

    const touch = e.touches[0];
    const diffX = touch.clientX - startX;

    if (diffX > 50) {
      prevSlide();
      setStartX(null);
    } else if (diffX < -50) {
      nextSlide();
      setStartX(null);
    }
  };

  const [startX, setStartX] = useState(null);

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="slider-container">
      <div
        className="slider"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div className="slide" key={index}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </div>
      {images.length > 1 &&
        <>
	        <button className="prev" onClick={prevSlide}>❮</button>
	        <button className="next" onClick={nextSlide}>❯</button>
        </>
      }
      <div className="slide-indicator">
        {currentIndex + 1}/{images.length}
      </div>
    </div>
  );
}

export default Slider;
