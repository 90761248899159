import React from "react";
import styles from './style.module.scss';
import {Input} from "../components/Form/Input";
import searchInp from '../../assets/icons/searchInp.svg';
import filtersSvg from "../../assets/icons/filters.svg";
import imgPng from "../../assets/img.png";
import {convertDateString, daysLeft} from "../../helpers/utiles";

export const OfferCart = function ({...rest}) {

  const {title, price, photo, created_at, article,quantity,warehouse,delivery, brand, vendor_title} = rest;

  return (
    <div className={`${styles.offerCart} ${rest.active ? styles.active : ''} G-border-gray`} onClick={rest?.onClick}>
      <div className='G-flex-justify-content-space'>
        <p className={`G-fs-14`}>
          {title}
        </p>
        <div className={styles.threeDotsMenu}>
          <span className={styles.dot}></span>
          <span className={styles.dot}></span>
          <span className={styles.dot}></span>
        </div>
      </div>
      <div className='G-flex-justify-content-space'>
        <p className={`${styles.bidName}`}>
          <span className={`${styles.price} G-fs-16`}>{price?.formatted.replace('р.', '₽')}</span>
          <span className={`mob-display-none ${styles.quantity}`}>{quantity.formatted}</span>
        </p>
        <span className='show-on-mobile'>{quantity.formatted}</span>
      </div>

      <div className={`mob-display-none ${styles.cartInfo}`}>
        <span className='G-text-gray'>{article}, {brand} </span>
        <span className='G-text-gray'>{warehouse}{`${daysLeft(delivery) ? ',': ''}`} {daysLeft(delivery)}</span>
        <span className='G-text-gray'>{vendor_title}</span>
        <span className='G-text-gray'>{convertDateString(created_at)}</span>
      </div>
      {photo !== null && <>
        {photo?.includes('http') && <div className={`${styles.photo} mob-display-none`}>
	        <img src={photo} alt=""/>
        </div>}
        </>}
      <div className={`show-on-mobile ${styles.prodDesc}`}>
        <span className='G-text-gray'>{article}, {brand}</span>
        <div className='G-flex-justify-content-space'>
          <span className='G-text-gray'>{warehouse}{`${daysLeft(delivery) ? ',': ''}`} {daysLeft(delivery)}</span>
          <span className='G-text-gray'>{vendor_title}</span>
        </div>
      </div>
    </div>
  )
};