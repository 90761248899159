import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import axios from "axios";

export interface adsState {
  data: any
  isLoading: boolean,
  error?: any
}

const initialState: adsState = {
  data: {},
  isLoading: true
}

export const getAds = createAsyncThunk('ads/getAds', async ({...args}: any, thunkAPI) => {
  try {
    return axios.get('https://virtserver.swaggerhub.com/riderufa/qrq_core/1.0.0/ads/', { params: args})
  } catch (err: any) {
    console.log(err.message);
    return thunkAPI.rejectWithValue({ error: err.message })
  }
})

export const AdsSlice = createSlice({
  name: 'ads',
  initialState,
  reducers: {},
  extraReducers:(builder) => {
    builder.addCase(getAds.pending, (state:adsState) => {
      state.isLoading = true
    })
    builder.addCase(getAds.fulfilled, (state:typeof initialState, action: any) => {
      console.log(action.payload?.data, 444);
      state.data.values = action.payload?.data?.map((item: any) => {
        return {name:item.category_handle, label: item.category_name, count: '', category_id: item.category_id}
      });
      state.isLoading = false;
    })
    builder.addCase(getAds.rejected, (state:adsState, action) => {
      state.isLoading = false
      // state.error = action.error.message
    })
  }
});

export default AdsSlice.reducer;