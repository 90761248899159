// src/App.js
import React from 'react';
import AppRoutes from './routes';
import { NavigationProvider } from './contexts/NavigationProvider';
import { BrowserRouter } from 'react-router-dom';
import { Providers } from "./redux/provider";
import BreakpointsContextProvider from "./helpers/breakpoints";

const App = () => (
  <Providers>
    <BreakpointsContextProvider>
      <BrowserRouter>
        <NavigationProvider>
          <AppRoutes />
        </NavigationProvider>
      </BrowserRouter>
    </BreakpointsContextProvider>
  </Providers>
);

export default App;
