import React from "react";
import styles from './style.module.scss';
import {useSearchParams} from "react-router-dom";
import {FilterForm} from "./filterForm";
import useSearchParamHelper from "../../helpers/useSearchParamHelper";
import {Product} from "./product";


export const RightSection = function () {
  let [searchParams, setSearchParams] = useSearchParams();
  const {get} = useSearchParamHelper()
  return (
    <div className={styles.rightSection}>
      {get('filterForm') && (
        <FilterForm/>
      )}
      {get('activeOffer') && (
        <Product/>
      )}
    </div>
  )
};