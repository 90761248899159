import React from "react";
import styles from './style.module.scss';
import {Input} from "../components/Form/Input";
import searchInp from '../../assets/icons/searcGray.svg';

export const Search = function ({text = '', handleSearch}) {
  return (
    <div className={`${styles.searchInput}`}>
      <Input defaultValue={text} isSearch={true} icon={searchInp} handleSearch={handleSearch}/>
    </div>
  )
};