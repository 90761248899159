import React, {useContext, useState} from "react";
import {ButtonBase} from "../components/Form/Button";
import {Link} from "react-router-dom";
import './style.scss';
import {Input} from "../components/Form/Input";
import CheckBoxSquare from "../components/Form/CheckBox/checkBoxSquare";
import Paths from "../../routes/paths";

export const Auth = function () {
  const [remember, setRemember] = useState(false);

  return (
    <div className={`auth`}>
      <div className="cover">
        {/*<img src={MeanSvg} alt="cover"/>*/}
      </div>
      <div className="actions">
        <h4 className='G-fs-24'>Sign in to our platform</h4>
        <div className="authInputs">
          {/*<Input placeholder='name@flowbite.com' label='Email address' icon={MailSvg}/>*/}
          {/*<Input placeholder='••••••••••' label='Password' icon={UserSvg}/>*/}
          <div className="authRemember">
            <CheckBoxSquare label='Remember me' value={remember} onChange={() => setRemember(!remember)}/>
            <span className='G-blue'>Forgot password?</span>
          </div>
        </div>
        <div className="authButtons">
          <ButtonBase classes='P-btn-primary md' showArrow={true} onClick={() => {

          }}>
              Sign In
          </ButtonBase>
          <ButtonBase classes='P-btn-link md' onClick={() => {}}>
            Sign in with magic link 🪄
          </ButtonBase>
        </div>
      </div>
      <div className="noRegistered">
        <p className='G-fs-14'>Not registered? <span className='G-blue'>Create account </span></p>
      </div>
      <div className="authSocial">
        <span className="line"></span>
        <span className="signWith">Or Sign With</span>
        <span className="line"></span>
      </div>
      <div className="socialLogin">
        {/*<div className="socialLoginItem">*/}
        {/*  <img src={facebookSvg} alt=""/>*/}
        {/*</div>*/}
        {/*<div className="socialLoginItem">*/}
        {/*  <img src={googleSvg} alt=""/>*/}
        {/*</div>*/}
        {/*<div className="socialLoginItem">*/}
        {/*  <img src={appleSvg} alt=""/>*/}
        {/*</div>*/}
      </div>
    </div>
  )
};